import { isValidUUID } from '../util/uuid';
import http from './http';

const baseUrl = 'central-product-registry/';

export default {
  async getSevenProductOrders(page, size, identifier, status) {
    let route = `${baseUrl}ops/orders?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&productInstanceId=${identifier}`;
      } else {
        route += `&productInstanceName=${identifier}`;
      }
    }

    if (status) {
      route += `&productStatus=${status}`;
    }

    const response = await http.get(route);
    return response;
  },
  async getSevenProductOrderByUuid(productInstanceId) {
    const response = http.get(`${baseUrl}ops/orders/details/${productInstanceId}`);
    return response;
  },
  async getProductDeployments(productName) {
    const response = http.get(`${baseUrl}products/deployments/${productName}`);
    return response;
  },
  async installProductOrder(productInstanceId, productDeploymentId) {
    const response = http.put(`${baseUrl}ops/orders/actions/install?sevenProductId=${productInstanceId}&productDeploymentId=${productDeploymentId}`);
    return response;
  },
  async stopSubscription(subscriptionId) {
    const response = await http.put(`${baseUrl}ops/subscriptions/actions/deactivate/${subscriptionId}`);
    return response;
  },
  async startSubscription(subscriptionId) {
    const response = await http.put(`${baseUrl}ops/subscriptions/actions/activate/${subscriptionId}`);
    return response;
  },
};
